/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-dialog-container{
    padding:0;
}

.foo .mat-dialog-container  {
    padding: 0;
  }

.foo .mat-dialog-content{
      padding:0;
      margin:0;
  }

.submitbutton{
    color:white;
    background-color:#20496F;
}

.incomeheader{
  margin: 1% 0;
}

td.mat-cell{
  text-align: center;
}

.error_snackbar{
  margin-top: 5%;
  border:2px solid #d25950;
  color: #d25950;
  background-color: #f5ccba;
}

.success_snackbar{
  margin-top: 5%;
  border:2px solid #44685f;
  color: #44685f;
  background-color: #d1f0cd;
}

.error_snackbar .mat-simple-snackbar-action {
  color: #d25950 !important;
  background-color: #f5ccba;
  border-radius: 4px;
  border: 1px solid #d25950;
}

.mat-simple-snackbar-action {
  color: #44685f !important;
  background-color: #d1f0cd;
  border-radius: 4px;
  border: 1px solid #44685f;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  margin-bottom: 0 !important;
}